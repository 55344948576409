import { Container } from '@material-ui/core';
import React from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import './AboutUsPage.scss'
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

function AboutUsPage(){

    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "About Us",
          url: "https://www.simber.co.uk/about"
        },
    ]

    function jumpToContent() {
        var element = document.getElementsByClassName("first-paragraph")[0] as HTMLElement;
        window.scrollTo({behavior: "smooth", top: element.offsetTop})
    }
      
    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="About Simber Chauffeur Services" />
                <title>About Simber Chauffeur Services | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/about" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <div className="breadcrumbs"><StructuredBreadcrumb breadcrumbList={breadcrumbList}/></div>
                    <Container className="container about-text" maxWidth="lg">
                        <div className="about-wrapper service-picture"></div>
                        <div className="content about-content">
                            <h1 className="about-header"><span>About Us</span></h1>
                            <span className="jump-to-content-arrow" onClick={jumpToContent}></span>
                            <p className="first-paragraph">We are SIMBER, London's premier choice for discreet and personalised chauffeur services. Our team excels at crafting bespoke transportation solutions for discerning clients, both locally and internationally. From tailored executive transportation to a comprehensive range of services, we deliver seamless and sophisticated mobility experiences. But that's not all - we also curate unforgettable event experiences with access to exclusive hospitality and premium tickets to the UK's most sought-after events, paired with exceptional hospitality. Our team will take care of every detail, ensuring you and your guests enjoy an unforgettable experience. Whether it's a high-profile sports event, a sold-out concert, or a private gala, we'll get you there in style and comfort.</p>
                            <p className='center b'><a className="primary-button" href="https://www.simber.co.uk">Book Now</a></p>
                            <p>SIMBER was born from a shared vision to revolutionise the travel experience in London and the UK, offering comfort and luxury that not only exceeds our client's expectations but also prioritises the well-being of our planet. With a wealth of experience and a passion for making a positive impact, we founded a company that cares deeply about its clients and the environment. At SIMBER, we're committed to eco-responsibility and take action to reduce our carbon footprint. We're proud to partner with Ecologi, a leading climate change organisation, to fund high-standard climate projects that accelerate our transition to a sustainable future. For every journey we undertake, we plant trees to offset our carbon emissions. This is just one way we're working towards a brighter, more sustainable tomorrow.</p>
                            <div className='divider'></div>
                            <p className="third-paragraph">We understand the importance of trust, discretion, safety, reliability, and punctuality. Every client has unique needs, and we take a 'can-do' approach to delivering tailored solutions. With a wealth of experience in the industry and a proven track record of success, we're confident in our ability to provide the right service solution for you. We strive for service excellence, and our clients continue to choose us as their trusted transportation partner because of our unwavering commitment to their needs. We're honoured that they entrust us with their family, business associates, staff members, and guests, and we take pride in ensuring that every journey with us is safe, discreet, and of the highest quality.</p>
                            <p>Our team of professional, attentive, and discreet chauffeurs, paired with a premium fleet of luxury vehicles, is dedicated to delivering a seamless and exceptional experience for every occasion. From the moment you book with us, you can expect a high level of service that combines comfort, luxury, and efficiency.</p>
                            <p>Our range of services has earned us a loyal following, with satisfied clients consistently recommending us to friends and colleagues. We're proud of our reputation for delivering exceptional results, and we're committed to continuing to exceed your expectations with every journey and every event hospitality experience you decide to book.</p>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}

export default AboutUsPage;