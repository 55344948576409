
import React from 'react'
import VehiclesPage from './Components/VehiclesPages/VehiclesPage';
import CorporateChauffeursPage from './Components/ServicesPages/CorporateChauffeursPage'
import AboutUsPage from './Components/ServicesPages/AboutUsPage'
import AirportTransferPage from './Components/ServicesPages/AirportTransfersPage'
import LongDistancePage from './Components/ServicesPages/LongDistancePage'
import EventChauffeuringPage from './Components/ServicesPages/EventChauffeuringPage'
import LongTermPage from './Components/ServicesPages/LongTermPage'
import WeddingChauffeuringPage from './Components/ServicesPages/WeddingChauffeuringPage'
import PrivateToursPage from './Components/ServicesPages/PrivateToursPage'
import SeaportTransfersPage from './Components/ServicesPages/SeaportTransfersPage'
import GalleryPage from "./Components/GalleryPage"
import ResetPassPage from "./Components/ResetPassPage"
import ProfilePageWrapper, { ProfilePageTab } from "./Components/ProfilePages/ProfilePageWrapper"
import ProfileData from './Components/ProfilePages/ProfileData'
import MyBookings from './Components/ProfilePages/MyBookings'
import Payment from './Components/ProfilePages/Payment'
import Settings from './Components/ProfilePages/Settings'
import BookingDetails from './Components/ProfilePages/BookingDetails'
import GeneralTermsPage from './Components/Terms/GeneralTermsPage';
import ServiceTermsPage from './Components/Terms/ServiceTermsPage';
import ContactUsPage from './Components/ContactUsPage';
import CovidPage from './Components/CovidPage';
import EmailConfirmPage from './Components/EmailConfirmPage';
import PolicyTermsPage from './Components/Terms/PolicyTermsPage';
import BlogDetailPage from './Components/BlogDetailPage';
import BlogPage from './Components/BlogPage';
import App from './Components/App';
import { Switch, Route, Redirect} from 'react-router-dom';
import { MemoryRouter, useLocation } from 'react-router';
import SummaryPage from './Components/SummaryPage'
import {PaymentSuccessPage} from './Components/PaymentSuccessPage'
import {PaymentCanceledPage} from './Components/PaymentCanceledPage'
import LoginPage from "./Components/LoginPage"
import RegisterPage from "./Components/RegisterPage"
import HotelTransportPage from './Components/ServicesPages/HotelTransportPage';
import LuxuryMinibusHirePage from './Components/ServicesPages/LuxuryMinibusHirePage';
import AirportPageHeathrow from './Components/ServicesPages/Airports/AirportPageHeathrow';
import AirportPageGatwick from './Components/ServicesPages/Airports/AirportPageGatwick';
import ExecutiveChauffeursTransfersPage from './Components/ServicesPages/ExecutiveChauffeursTransfersPage';
import BmwI7Page from './Components/VehiclesPages/BmwI7Page';
import MercedesVClassPage from './Components/VehiclesPages/MercedesVClassPage';
import MercedesSClassPage from './Components/VehiclesPages/MercedesSClassPage';
import SenzatiPage from './Components/VehiclesPages/SenzatiPage';
import MinibusPage from './Components/VehiclesPages/MinibusPage';
import RollsRoycePage from './Components/VehiclesPages/RollsRoycePage';
import NotFound from './Components/NotFound';
import HospitalityPage from './Components/ServicesPages/HospitalityPage';

export default function RouterComponent(){

  return(
    <Switch>
      <Route exact path="/" component={App}></Route>
      <Route exact path="/home" component={App}></Route>
      <Route exact path="/paymentsuccess">
        <PaymentSuccessPage />
      </Route>
      <Route exact path="/paymentcanceled">
        <PaymentCanceledPage />
      </Route>
      <Route exact path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/confirmEmail">
        <EmailConfirmPage />
      </Route>
      <Route exact path="/resetPass">
        <ResetPassPage />
      </Route>
      <Route exact path="/register">
        <RegisterPage />
      </Route>
      <Route exact path="/profile/data">
        <ProfilePageWrapper initTab={ProfilePageTab.Profile}>
          <ProfileData />
        </ProfilePageWrapper>
      </Route>
      <Route exact path="/profile/bookings">
        <ProfilePageWrapper initTab={ProfilePageTab.MyBookings}>
          <MyBookings />
        </ProfilePageWrapper>
      </Route>
      <Route exact path="/profile/bookings/:id">
          <BookingDetails />
      </Route>
      <Route exact path="/profile/payment">
        <ProfilePageWrapper initTab={ProfilePageTab.Payment}>
          <Payment />
        </ProfilePageWrapper>
      </Route>
      <Route exact path="/profile/settings">
        <ProfilePageWrapper initTab={ProfilePageTab.Settings}>
          <Settings />
        </ProfilePageWrapper>
      </Route>
      <Route exact path="/summary" component={SummaryPage}></Route>
      <Route exact path="/vehicles">
        <VehiclesPage />
      </Route>
      <Route exact path="/vehicles/mercedes-s-class-chauffeur-hire">
      <MercedesSClassPage />
      </Route>
      <Route exact path="/vehicles/mercedes-v-class-chauffeur-hire">
        <MercedesVClassPage />
      </Route>
      <Route exact path="/vehicles/bmw-i7-car-hire">
        <BmwI7Page />
      </Route>
      <Route exact path="/vehicles/minibus-8-16-seater-hire">
        <MinibusPage />
      </Route>
      <Route exact path="/vehicles/mercedes-senzati-jet-class-travel">
        <SenzatiPage />
      </Route>
      <Route exact path="/vehicles/rolls-royce-luxury-car-hire">
        <RollsRoycePage />
      </Route>
      <Route exact path="/blog">
        <BlogPage />
      </Route>
      <Route exact path="/blog/:id">
        <BlogDetailPage />
      </Route>
      <Route exact path="/about">
        <AboutUsPage/>
      </Route>
      <Route exact path="/contact">
        <ContactUsPage />
      </Route>
      {/* <Route exact path="/covid19">
        <CovidPage />
      </Route> */}
      <Route exact path="/services/airport-transfers">
        <AirportTransferPage/>
      </Route>
      <Route exact path="/services/airport-transfers/heathrow">
        <AirportPageHeathrow/>
      </Route>
      <Route exact path="/services/airport-transfers/gatwick">
        <AirportPageGatwick/>
      </Route>
      <Route exact path="/services/corporate-chauffeur">
        <CorporateChauffeursPage/>
      </Route>
      <Route exact path="/services/executive-transfers">
        <ExecutiveChauffeursTransfersPage/>
      </Route>
      <Route exact path="/services/events-chauffeurs">
        <EventChauffeuringPage/>
      </Route>
      <Route exact path="/services/events-hospitality">
        <HospitalityPage/>
      </Route>
      <Route exact path="/services/wedding-chauffeur">
        <WeddingChauffeuringPage/>
      </Route>
      <Route exact path="/services/private-tours">
        <PrivateToursPage/>
      </Route>
      <Route exact path="/services/seaport-transfers">
        <SeaportTransfersPage/>
      </Route>
      <Route exact path="/services/long-distance-chauffeur">
        <LongDistancePage/>
      </Route>
      <Route exact path="/services/long-term-chauffeur">
        <LongTermPage/>
      </Route>
      <Route exact path="/services/strategic-partnerships-hotel-transport">
        <HotelTransportPage/>
      </Route>
      
      <Route exact path="/services/luxury-minibus-hire">
        <LuxuryMinibusHirePage/>
      </Route>
      <Route exact path="/terms/general">
        <GeneralTermsPage />
      </Route>
      <Route exact path="/terms/service">
        <ServiceTermsPage />
      </Route>
      <Route exact path="/terms/policy">
        <PolicyTermsPage />
      </Route>
      <Route exact path="/NotFound">
        <NotFound />  
      </Route>
      {/* <Route exact path="/gallery">
        <GalleryPage />
      </Route> */}
      <Redirect to="/NotFound"/>
      <MemoryRouter initialEntries={['/blog']} initialIndex={0}>
        <Switch>
          <Route path="/blog/*" children={<BlogPage />} />
        </Switch>
      </MemoryRouter>
    </Switch >
  )
}