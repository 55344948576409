import React from 'react';
import { Container } from '@material-ui/core';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import MetaTags from 'react-meta-tags';
import './HospitalityPage.scss'
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function HospitalityPage() {
    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
            description: "Hotel Transfers",
            url: "https://www.simber.co.uk/services/events-hospitality"
        },
    ]

    const scrollToContent = () => {
        var height = window.innerHeight
    
        window.scroll({top: height, behavior: 'smooth'});
    }

    return (
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Exclusive Access to London’s Biggest Sports and Music Events with Our Personalised Events Hospitality Service." />
                <title>Experience the Ultimate in Events Hospitality with Simber | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/events-hospitality" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent />
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="lg" className="container">
                        <div className="hospitality-wrapper service-picture"></div>
                        <div className="content">
                            <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                            <img className="scroll-to-content-arrow" src={require("../../images/pictograms/arrow_light.png")} onClick={() => scrollToContent()}/>
                            <h1><span>Events Hospitality with Simber</span></h1>
                            <p>Imagine being part of the action at London’s most iconic sports and music events, without the hassle of organisation. At Simber, we’re excited to introduce our new Events Hospitality service, designed to deliver unforgettable experiences for you and your guests. Our expert team will take care of every detail, ensuring you enjoy premium seats, world-class amenities, and unparalleled access to the biggest events in London and the UK.</p>
                            <p>From VIP hospitality at Wembley Stadium for an electrifying football match, to the thrill of NFL in London at Tottenham Hotspur Stadium, or the grass courts of the All England Lawn Tennis Club (Wimbledon), where the world’s top players compete - we’ll make your event experience truly unforgettable. Whether you’re entertaining clients, celebrating a special occasion, or simply treating yourself to a unique experience, our Events Hospitality service has got you covered.</p>
                            <p><a className="primary-button center" href="https://www.simber.co.uk/contact">Contact us</a></p>
                            <div className="picture-section">
                                <img alt="Horse racing." src={require("../../images/servicepages/hospitality_1.webp")}/>
                                <div className="content">
                                    <h2>What We Offer</h2>
                                    <p>
                                        <ul className="dot-list">
                                            <li><strong>VIP Experiences:</strong> Get hospitality tickets and boxes to major events, including Premier League football matches, international rugby and cricket, Wimbledon Tennis, NFL, and the biggest music events in London.</li>
                                            <li><strong>Luxury Seating:</strong> Enjoy the best seats in the house with incredible views, perfect for business or pleasure.</li>
                                            <li><strong>Personalised Service:</strong> Our dedicated team will take care of all the arrangements, from ticket booking to transportation and hospitality.</li>
                                            <li><strong>World-Class Amenities:</strong> Enjoy access to exclusive VIP areas, complete with fine dining, bars, and entertainment.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div> 
                            <div className='devider'></div>
                            <h3>How It Works</h3>
                            <p>Booking event tickets doesn’t have to be daunting. With so many options, prices, and choices, it can feel overwhelming. At Simber, we simplify the process with a personalised approach that takes the guesswork out of your event experience. Our dedicated team is here to guide you through every step, ensuring you make the best choices for your needs and preferences.</p>
                            <p>Whether you’re unsure about which package to choose or need advice on the venue layout, we’re here to assist you. Our goal is to provide a seamless, stress-free experience, so you can focus on enjoying the event.</p>
                            <p><strong>How We Make It Easy:</strong></p>
                            <p>
                                <strong>1. Get in Touch:</strong><br/> Contact us to discuss your event, the number of guests, and your specific preferences. We’ll take care of the details.<br/>
                                <strong>2. Personalised Itinerary:</strong><br/> We’ll work closely with you to craft a tailor-made itinerary that suits your needs, including:
                                <ul className="dot-list">
                                <li><strong>Ticket Booking:</strong> We’ll secure your hospitality tickets to the event or show, ensuring you get the best options.</li>
                                <li><strong>Hospitality Arrangements:</strong> Enjoy premium services and exclusive amenities at the venue.</li>
                                <li><strong>Transportation:</strong> Skip the hassle of logistics with a dedicated chauffeur to take you to and from the venue in comfort and style.</li>
                                </ul>
                                <strong>3. Event Day Experience:</strong><br/> On the big day, your experience starts the moment you step into one of our luxury vehicles. Relax and travel in style, enjoy the event or show, and be treated to top-notch hospitality service throughout. You and your guests will be pampered from start to finish.<br/>
                                <strong>4. Return Safely:</strong><br/> Once the event wraps up, your chauffeur will safely transport you and your guests to your desired location, ensuring a smooth and relaxed end to your experience.<br/>
                            </p>
                            <p>Ready to experience the ultimate in Events Hospitality? <a href="https://www.simber.co.uk/contact">Contact us</a> today to discuss your requirements and let us take care of the rest.</p>
                            <div className='devider'></div>
                            <h2 className="faq-section-header">Frequently Asked Questions</h2>
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent 
                                    question="What events do you offer tickets for?" 
                                    answer="We offer hospitality tickets to a wide range of events, including:"
                                    answerList={["Hospitality for Premier League football", "Hospitality for International football games at Wembley Stadium", "Hospitality for Tennis at Wimbledon", "Hospitality for Music events & Concerts", "Hospitality for Formula 1 Racing at Silverstone", "Hospitality for Horse racing at Ascot"]}
                                />
                                <AccordionComponent
                                    question="What kind of transportation arrangements do you offer?"
                                    answer="We provide luxury transportation vehicle options, including:"
                                    answerList={["Luxury Saloon; Mercedes S-Class and BMWi7", "MPV’s (Multi-Purpose Vehicles), Mercedes V-Class & Mercedes Senzati Jet Class", "Executive Minibus to accommodate your entourage (12, 16,19 and 23 seater Minibus)."]}
                                />
                                <AccordionComponent
                                    question="Can I customise my event experience?"
                                    answer="Yes, our team will work with you to create a personalised itinerary that meets your preferences and requirements."
                                />
                                <AccordionComponent
                                    question="How do I get in touch?"
                                    answer="Contact us through our website or email Hospitality@simber.co.uk with all of your requirements. Our team will work with you to make sure you find the package that is best suited to you and your guests."
                                />
                            </div>
                            <div className='devider'></div>
                            <p><a className="primary-button center" href="https://www.simber.co.uk/contact">Contact us</a></p>
                            <p>Ready to experience the ultimate in Events Hospitality? Contact us today to discuss your requirements and let us take care of the rest.</p>
                        </div>
                    </Container>
                </main>
                <FooterComponent />
            </div>
        </div>
    )
}