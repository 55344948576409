import React, { useEffect, useState } from 'react';
import HeaderComponent from '../HeaderComponent';
import FooterComponent from '../FooterComponent';
import "./VehiclesPage.scss"
import { Grid, Tooltip, Button, Container } from '@material-ui/core';
import { ContactReasons } from '../../dtos/ContactData';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';
import SearchComponent from '../SearchComponent';

export default function VehiclesPage() {

  const [offer, setOffer] = useState<ContactReasons | undefined>(undefined);
  const [init, setInit] = useState(true);
  const [bookingOpen, setBookingOpen] = useState(false);

  useEffect(() => {
    if(init) {
      window.scrollTo({top:0})
      setInit(false);
    }
  })

  const scrollToContent = () => {
    var height = window.innerHeight

    window.scroll({top: height, behavior: 'smooth'});
  }

  const breadcrumbList: IBreadcrumb[] = [
    {
      description: "Vehicles",
      url: "https://www.simber.co.uk/vehicles"
    },
  ]

  if(offer){   
      return(
          <div className="wrapper">
              <HeaderComponent />
              <div className="form-wrapper">
                  <Button variant="outlined" className="back-button" onClick={() => setOffer(undefined)}>Back to prices</Button>
              </div>
              <FooterComponent />
          </div>
      )     
  }
  else {
  return (
      <div className="service-background-wrapper">
          <MetaTags>
              <meta name="description" content="Discover the luxury vehicle fleet by Simber. Choose your favourite car and get the best of the bespoke chauffeur services in London and around the UK." />
              <title>Luxury Cars for Hire | Simber</title>
              <link rel="canonical" href="https://www.simber.co.uk/vehicles" />
          </MetaTags>
          <div className="service-page-wrapper">
            <div className="content-wrapper"></div>
            <HeaderComponent />
            <main>
              <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
              <Container maxWidth="xl" className="container vehicles-container">
              <div className="vehicles-wrapper service-picture"></div>
              <div className="content">
                <div className="vehicle-page-slogen">
                  <h1 className="vehicles-header"><span>Premium Chauffeur-Driven Vehicles for Every Occasion</span></h1>
                  <img src={require("../../images/pictograms/arrow_light.png")} onClick={() => scrollToContent()}/>
                </div>
                <div className="vehicles-page-cards">
                  <p>Discover our fleet of vehicles for your unforgettable London journey. Whether you need it just for you or you travel with someone by your side, we can offer the best of the best - luxury electric vehicles, sedans, and even minibus for travelling in larger groups. </p>
                  <p>Explore our offer and find out why our clients always choose Simber to provide chauffeur services for different occasions. These are the vehicles we offer.</p>
                  <div className="vehicle-card">
                    <div className="vehicle-card-content">
                      <h2 className="vehicle-header">Luxury Saloon</h2>
                      <p className="vehicle-text">Do you want to showcase your dedication to elegant travel or leave a great first impression? There's no better way to do that than our luxury vehicles, available to hire with a professional chauffeur by your side.</p>
                      <h3 className="vehicle-sub-header">Mercedes S580E or similar</h3>
                      <p className="vehicle-text">The spacious & carefully crafted interior of the Mercedes S580e & BMW i7 showcase the attention paid to every detail, designed to pamper you in sustainable luxury. Eco friendly engines, comfortable leather seats, huge windows, ambient lighting, heated and cooled reclining seats, climate control system create a haven of comfort and tranquility - which is exactly what you need for your chauffeur rides.</p>
                      <div className="vehicle-stats">
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                          <span>Passengers: 3</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                          <span>Checked luggage: 1</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                          <span>Hand luggage: 2</span>
                        </div>
                      </div>
                      <div className="vehicle-prices">
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Hourly Rate (minimum 4 hours)</span><span className="vehicles-price">£65</span></div>
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Heathrow to Central London</span><span className="vehicles-price">£175</span></div>
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Daily Rate (based on 8 hours)</span><span className="vehicles-price">£520</span></div>
                        <div className="vehicles-devider"></div>
                      </div>
                      <span>*All pricing illustrative & subject to vat* </span>
                    </div>
                    <div className="vehicles-image-wrapper">
                      <img alt="Faded borderless image of a Mercedes S class, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/luxury_saloon.png")}/>
                      <button className="primary-button" onClick={() => setBookingOpen(!bookingOpen)}>Book Now</button>
                    </div>
                  </div>
                  
                  <div className="vehicle-card">
                    <div className="vehicle-card-content">
                      <h2 className="vehicle-header">Premium MPV</h2>
                      <h3 className="vehicle-sub-header">Mercedes V Class</h3>
                      <p className="vehicle-text">The Mercedes V-Class is a leading luxury people carrier, known for its versatile seating options and distinctive style. With advanced technology, it offers safety and comfort for up to 7 passengers, making it ideal for meetings on the move, tours, events, and airport transfers.</p>
                      <p className="vehicle-text">Whether for business trips, family outings, or special occasions, the Mercedes V-Class provides premium travel with its refined interior and design. From airport transfers and corporate events to city tours, Mercedes V-Class chauffeur service combines flexibility with sophistication, ensuring a secure and comfortable journey for all.</p>
                      <p><a href='https://www.simber.co.uk/vehicles/mercedes-v-class-chauffeur-hire'>Read more...</a></p>
                      <div className="vehicle-stats">
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                          <span>Passengers: 7</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                          <span>Checked luggage: 5</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                          <span>Hand luggage: 4</span>
                        </div>
                      </div>
                      <div className="vehicle-prices">
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Hourly Rate (minimum 4 hours)</span><span className="vehicles-price">£55</span></div>
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Heathrow to Central London</span><span className="vehicles-price">£145</span></div>
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Daily Rate (based on 8 hours)</span><span className="vehicles-price">£440</span></div>
                        <div className="vehicles-devider"></div>
                      </div>
                      <span>*All pricing illustrative & subject to vat* </span>
                    </div>
                    <div className="vehicles-image-wrapper">
                      <img alt="Faded borderless image of a Mercedes V class, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/mercedes_v_class.png")}/>
                      <button className="primary-button" onClick={() => setBookingOpen(!bookingOpen)}>Book Now</button>
                    </div>
                  </div>
                  <div className="vehicle-card">
                    <div className="vehicle-card-content">
                      <h2 className="vehicle-header">Luxury MPV</h2>
                      <h3 className="vehicle-sub-header">Senzati Jet Class</h3>
                      <p className="vehicle-text">The Mercedes Senzati Jet Class is a luxurious, custom-built van that offers a first-class experience on the road. Inspired by private jets, it features a spacious and refined interior with plush leather seating, ambient lighting, and cutting-edge technology.</p>
                      <p className="vehicle-text">This vehicle is tailored for those seeking comfort and sophistication during travel, making it ideal for VIP transfers, business trips, or exclusive events. Its interior layout can be customized with lounge seating, conference tables, and multimedia systems, ensuring passengers travel in both style and productivity.</p>
                      <p><a href='https://www.simber.co.uk/vehicles/mercedes-senzati-jet-class-travel'>Read more...</a></p>
                      <div className="vehicle-stats">
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                          <span>Passengers: 4</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                          <span>Checked luggage: 2</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                          <span>Hand luggage: 2</span>
                        </div>
                      </div>
                      <div className="vehicle-prices">
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Heathrow to Central London</span><span className="vehicles-price">£320</span></div>
                        <div className="vehicles-devider"></div>
                        <div className="vehicle-prices-row"><span>Daily Rate (based on 8 hours)</span><span className="vehicles-price">£760</span></div>
                        <div className="vehicles-devider"></div>
                      </div>
                      <span>*All pricing illustrative & subject to vat* </span>
                    </div>
                    <div className="vehicles-image-wrapper">
                      <img alt="Faded borderless image of a Mercedes V class, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/senzati_van.png")}/>
                      <button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk/contact"}>Enquire for a tailor made quote</button>
                    </div>
                  </div>
                  {/* <div className="vehicle-card">
                    <div className="vehicle-card-content">
                      <h2 className="vehicle-header">Range Rover</h2>
                      <p className="vehicle-text">Quintessentially British and at home in any surrounding while being elegant, discreet and stylish. The Range Rover Autobiography with extended rear legroom has become the vehicle of choice for the discerning passenger. Perfect for a shopping spree around London, a high-profile event, or even as your vehicle of choice to your private jet.</p>
                      <h3 className="vehicle-sub-header">Range Rover Autobiography Hire</h3>
                      <p className="vehicle-text">If you need reasons to hire a chauffeur driving Range Rover Autobiography edition, we have a few of them. It's one of the best vehicles featuring modern technologies but also off-road capabilities. That means you feel comfortable even w/hile travelling through challenging terrains. Still, you can hire this vehicle for urban explorations, as well as city excursions and several business occasions, enjoying the perfect and powerful performance on the streets.</p>
                      <span className="vehicle-inquiry" id="rover" onClick={(e) => getOfferClick(e)}>Enquire for a tailor made quote <img src={require("../../images/pictograms/arrow.png")}/></span>
                      <div className="vehicle-stats">
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                          <span>Passengers: 3</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                          <span>Checked luggage: 2</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                          <span>Hand luggage: 3</span>
                        </div>
                      </div>
                    </div>
                    <img alt="Faded borderless image of a Range Rover, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/range_rover.png")}/>
                  </div> */}
                  <div className="vehicle-card">
                    <div className="vehicle-card-content">
                      <h2 className="vehicle-header">Rolls Royce Ghost</h2>
                      <p className="vehicle-text">The Rolls Royce Ghost is the ultimate luxury vehicle delivering at all times an unforgettable chauffeur driven experience. This vehicle is every bit as impressive on the exterior as well as the interior. Therefore, it is often the vehicle chosen for special occasions, weddings or lavish events. If you wish to make an impression and enjoy a ride that is totally unique, contact Simber for a tailor made quote specific to your requirements.</p>
                      <h3 className="vehicle-sub-header">Rolls Royce Ghost Hire</h3>
                      <p className="vehicle-text">Rolls Royce Ghost offers an unknown world of luxury and prestige, as well as automotive opulence, timeless design, and exceptional attention to detail. With its powerful engine and elegant interior, it's one of the safest vehicles to offer an exclusive ride to the passengers. So, whether it's for your wedding, honeymoon, or other event, you deserve to experience this excellence, making every moment worth the effort.</p>
                      <p><a href='https://www.simber.co.uk/vehicles/rolls-royce-luxury-car-hire'>Read more...</a></p>
                      <div className="vehicle-stats">
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                          <span>Passengers: 3</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                          <span>Checked luggage: 1</span>
                        </div>
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                          <span>Hand luggage: 2</span>
                        </div>
                      </div>
                    </div>
                    <div className="vehicles-image-wrapper">
                      <img alt="Faded borderless image of a Rolls Royce Ghost, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/rolls_royce.png")}/>
                      <button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk/contact"}>Enquire for a tailor made quote</button>
                    </div>
                  </div>
                  <div className="vehicle-card">
                    <div className="vehicle-card-content">
                      <h2 className="vehicle-header">8 to 16 seater Premium Minibus</h2>
                      <p className="vehicle-text">Simber can offer clients a number of Mercedes-Benz executive Minibuses which are available in various sizes; ranging from 8, 12 and 16 seaters. Perfect for transporting groups around the United Kingdom for corporate or social events, financial roadshows, sightseeing tours or special occasions. The Mercedes chauffeur driven minibuses are equipped with entertainment systems and comfortable leather seats making them a stylish and spacious option for your friends, family or business associates.</p>
                      <h3 className="vehicle-sub-header">8 Seater Minibus</h3>
                      <p className="vehicle-text">Elevate every group travel with exceptional spaciousness and comfort. These minibuses are designed for families or groups of friends but also for corporate teams on a trip together. With versatile seating configurations, everyone enjoys ample legroom and convenience.</p>
                      <p className="vehicle-text">From airport transfers to daily trips, the 8 Seater minibus offers a unique blend of style and practicality, ensuring a delightful journey. Every time you need safe and efficient group transportation, the 8 Seater minibus is here to meet all your requirements.</p>
                      <h3 className="vehicle-sub-header">16 Seater Minibus Hire</h3>
                      <p className="vehicle-text">For even larger groups, we recommend the 16 Seater minibuses designed to provide enough space and comfort for every passenger. You can use it for corporate events because the advanced features and modern amenities provide convenience and safety for the whole group of passengers. Reach your preferred destination in style, even when you travel with friends and family, or maybe corporate partners or some special group of people by your side.</p>
                      <p><a href='https://www.simber.co.uk/vehicles/minibus-8-16-seater-hire'>Read more...</a></p>
                      <h3>Welcoming More than 16 Travellers On Board</h3>
                      <p>Travel in comfort and style with our premium minibuses, accommodating 12 to 29 passengers, perfect for corporate events, roadshows, sightseeing, and special occasions. With plush seating, entertainment systems, and experienced drivers, our minibuses ensure a seamless and luxurious journey for groups of all sizes.</p>
                      <div className="vehicle-stats">
                        <div className="vehicle-stat">
                          <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                          <span>Passengers: From 10 to 29</span>
                        </div>
                      </div>
                    </div>
                    <div className="vehicles-image-wrapper">
                      <img alt="Faded borderless image of a Mercedes minibus, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/minibus.png")}/>
                      <button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk/contact"}>Enquire for a tailor made quote</button>
                    </div>
                  </div>
                </div>
              </div>
              </Container>
              <SearchComponent isOpen={bookingOpen} close={() => setBookingOpen(false)}/>
            </main>
            <FooterComponent />
          </div>
      </div>
  )
  }
}